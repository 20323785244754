import React from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/CommonSection/CommonSection'
import { Col, Container, Form, Row, FormGroup } from 'reactstrap'

import '../styles/checkout.css';
import { useSelector } from 'react-redux';

const Checkout = () => {

  const totalQty = useSelector(state => state.cart.totalQuantity)
  const totalAmount = useSelector(state => state.cart.totalAmount)

  return (
    <Helmet title='Checkout'>
      <CommonSection title="Checkout" />
      <section>
        <Container>
          <Row>
            <Col lg='8'>
              <h6 className="mb-4 fw-bold">Podaci o dostavi</h6>
              <Form className='billing__form'>
                <FormGroup className='form__group'>
                  <input type="text" placeholder='Unesite ime i prezime...' required />
                </FormGroup>
                <FormGroup className='form__group'>
                  <input type="email" placeholder='Unesite email...' required />
                </FormGroup>
                <FormGroup className='form__group'>
                  <input type="text" placeholder='Unesite broj telefona...' required />
                </FormGroup>
                <FormGroup className='form__group'>
                  <input type="text" placeholder='Unesite naziv ulice i broj...' required />
                </FormGroup>
                <div className='d-flex justify-content-between gap-10'>
                <FormGroup className='form__group col-md-6 p-0'>
                  <input type="text" placeholder='Unesite grad...' required />
                </FormGroup>
                <FormGroup className='form__group col-md-6 p-0'>
                  <input type="text"  placeholder='Unesite poštanski kod...' required />
                </FormGroup>
                </div>
                <FormGroup className='form__group'>
                  <textarea placeholder='Unesite napomenu...' />
                </FormGroup>
              </Form>
            </Col>
            <Col lg='4'>
              <div className="checkout__cart">
                <h6>Total Qty: <span>{totalQty} items</span></h6>
                <h6>Subtotal: <span>${totalAmount}</span></h6>
                <h6>
                  <span>Shipping: <br />
                    Free shipping
                  </span>
                  <span>$0</span>
                </h6>
                <h4>Total Cost: <span>${totalAmount}</span></h4>
                <button className="buy__btn auth__btn w-100">Place an order</button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>

  )
}

export default Checkout