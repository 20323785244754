import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/CommonSection/CommonSection'
import { Col, Container, Row } from 'reactstrap'
import '../styles/shop.css'
import ProductsList from '../components/UI/ProductsList'
import useGetData from '../custom-hooks/useGetData'

const Shop = () => {

  const { data: products, loading } = useGetData('products');


  const [productsData, setProductsData] = useState([]);
  useEffect(() => { setProductsData(products) }, [products])

  const handleFilter = (e) => {
    const filterValue = e.target.value;

    console.log("filterValue+++",filterValue)
    if (filterValue === "all") {
      setProductsData(products);
    }
    if (filterValue === "sofa") {
      const filteredProducts = products.filter((item) => item.category === "sofa");
      setProductsData(filteredProducts);

    }
    if (filterValue === "mobile") {
      const filteredProducts = products.filter((item) => item.category === "mobile");
      setProductsData(filteredProducts);

    }
    if (filterValue === "watch") {
      const filteredProducts = products.filter((item) => item.category === "watch");
      setProductsData(filteredProducts);

    }

    if (filterValue === "chair") {
      const filteredProducts = products.filter((item) => item.category === "chair");
      setProductsData(filteredProducts);

    }
    if (filterValue === "wireless") {
      const filteredProducts = products.filter((item) => item.category === "wireless");
      setProductsData(filteredProducts);
    }


  }

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const searchProducts = products.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
    setProductsData(searchProducts);

  }

  return (
    <Helmet title="Shop">
      <CommonSection title="Products" />
      <section>
        <Container>
          <Row>
            <Col lg='3' md='6'>
              <div className="filter__widget">
                <select onChange={handleFilter}>
                  <option value='all'>Filter By Category</option>
                  <option value="sofa">Sofa</option>
                  <option value="mobile">Mobile</option>
                  <option value="chair">Chair</option>
                  <option value="watch">Watch</option>
                  <option value="wireless">Wireless</option>
                </select>
              </div>
            </Col>
            <Col lg='3' md='6'>
              <div className="filter__widget text-right" >
                <select>
                  <option>Sort By</option>
                  <option value="ascending">Ascending</option>
                  <option value="descending">Descending</option>
                </select>
              </div>
            </Col>
            <Col lg='6' md='12' className='text-end'>
              <div className="search__box">
                <input type="text" placeholder='Search' onChange={handleSearch} />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='pt-0 text-center'>
        <Container>
          <Row className='no__products'>
            {loading ?
              (<h2>Loading...</h2>)
              :
              (<ProductsList data={productsData} />)
            }
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

export default Shop