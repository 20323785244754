import React from "react";
import { Col, Container, Row } from "reactstrap";
import '../styles/dashboard.css'
import useGetData from "../custom-hooks/useGetData";
const Dashboard = () => {

  const {data: products} = useGetData('products')
  const {data: users} = useGetData('users')
  
  return (
    <section>
      <Container>
        <Row>
          <Col className="lg-3">
            <div className="revenue__box">
              <h4>Total Sales</h4>
              <span>$7890</span>
            </div>
          </Col>
          <Col className="lg-3">
            <div className="orders__box">
              <h4>Orders</h4>
              <span>$789</span>
            </div>
          </Col>
          <Col className="lg-3">
            <div className="products__box">
              <h4>Total Products</h4>
              <span>{products.length}</span>
            </div>
          </Col>
          <Col className="lg-3">
            <div className="users__box">
              <h4>Total Users</h4>
              <span>{products.length}</span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dashboard;
