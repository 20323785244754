import React, { useEffect, useRef, useState } from 'react'

import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/CommonSection/CommonSection';

import '../styles/product-details.css'

import { motion } from 'framer-motion';
import ProductsList from '../components/UI/ProductsList';
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux/slices/cartSlice';
import { toast } from 'react-toastify';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import useGetData from '../custom-hooks/useGetData';



const ProductDetails = () => {

  const [product, setProduct] = useState({});

  const dispatch = useDispatch();
  const [tab, setTab] = useState('desc');
  const [rating, setRating] = useState(null);
  const reviewUser = useRef('');
  const reviewMsg = useRef('');
  const { id } = useParams();

  const { data: products } = useGetData('products');
  const { imgUrl, title, price, description, shortDesc, category } = product;

  const docRef = doc(db, 'products', id)

  useEffect(() => {
    const getProduct = async () => {
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setProduct(docSnap.data())
      } else {
        console.log("No product!");
      }
    }

    getProduct();
  }, [])

  const relatedProducts = products.filter((item) => item.category === category);

  const submitHandler = (e) => {
    e.preventDefault();

    const reviewUserName = reviewUser.current.value;
    const reviewUserMsg = reviewMsg.current.value;

    const reviewObj = {
      userName: reviewUserName,
      text: reviewUserMsg,
      rating
    }

    console.log("reviewObj++", reviewObj)

    toast.success('Review submitted')
  }

  const addToCart = () => {
    dispatch(cartActions.addItem({
      id,
      imgUrl,
      title,
      price
    }));

    toast.success('Product added successfully')

  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [product])

  return (
    <Helmet title={title}>
      <CommonSection title={title} />
      <section className='pt-0'>
        <Container>
          <Row>
            <Col lg='6'>
              <img src={imgUrl} alt='test' />
            </Col>
            <Col lg='6'>
              <div className='product__details'>
                <h2>{title}</h2>
                <div className='product__rating d-flex align-items-center gap-5 mb-4'>
                  <div>
                    <span><i className="ri-star-s-fill"></i></span>
                    <span><i className="ri-star-s-fill"></i></span>
                    <span><i className="ri-star-s-fill"></i></span>
                    <span><i className="ri-star-s-fill"></i></span>
                    <span><i className="ri-star-half-s-fill"></i></span>
                  </div>
                </div>
                <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                  <span className='product__price'>${price}</span>
                  <span>Category: {category?.toUpperCase()}</span>
                </div>
                <p className='mt-3'>{shortDesc}</p>
                <motion.button whileTap={{ scale: 1.2 }} className='buy__btn' onClick={addToCart}>Add to Cart</motion.button>
              </div>
            </Col>
            <section>
              <Container>
                <Row>
                  <Col lg='12'>
                    <div className="tab__wrapper d-flex align-items-center" style={{ gap: 10 }}>
                      <h6 className={`${tab === 'desc' ? 'active__tab' : ''}`} onClick={() => setTab('desc')}>Description</h6>
                      <h6 className={`${tab === 'rev' ? 'active__tab' : ''}`} onClick={() => setTab('rev')}>Reviews</h6>
                    </div>
                    {tab === 'desc' ? (
                      <div className="tab__content mt-5">
                        <p>{description}</p>
                      </div>
                    ) : (
                      <div className='product__review mt-5'>
                        <div className='review__wrapper'>
                          
                          <div className='review__form mt-5'>
                            <h4>Leave your experience</h4>
                            <form action='' onSubmit={submitHandler}>
                              <div className="form__group">
                                <input type="text" placeholder='Enter name' ref={reviewUser} required />
                              </div>
                              <div className="form__group d-flex align-items-center rating__group" style={{ gap: 30 }}>
                                <motion.span whileTap={{ scale: 1.2 }} onClick={() => setRating(1)}>1<i className="ri-star-s-fill"></i></motion.span>
                                <motion.span whileTap={{ scale: 1.2 }} onClick={() => setRating(2)}>2<i className="ri-star-s-fill"></i></motion.span>
                                <motion.span whileTap={{ scale: 1.2 }} onClick={() => setRating(3)}>3<i className="ri-star-s-fill"></i></motion.span>
                                <motion.span whileTap={{ scale: 1.2 }} onClick={() => setRating(4)}>4<i className="ri-star-s-fill"></i></motion.span>
                                <motion.span whileTap={{ scale: 1.2 }} onClick={() => setRating(5)}>5<i className="ri-star-s-fill"></i></motion.span>
                              </div>
                              <div className="form__group">
                                <textarea rows={4} ref={reviewMsg} type="text" placeholder='Review Message...' />
                              </div>
                              <button className='buy__btn' type='submit'>Submit</button>

                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                  </Col>
                  <Col lg='12' className='mt-5'>
                    <h2 className='related__title'>You might also like</h2>
                  </Col>
                  <ProductsList data={relatedProducts} />
                </Row>
              </Container>
            </section>
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

export default ProductDetails