import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'

import { Container, Row, Col } from 'reactstrap';

import './../styles/home.css';
import { motion } from 'framer-motion';
import heroImg from "../assets/images/hero-img.png";
import { Link } from 'react-router-dom';
import Services from '../services/Services';
import ProductsList from '../components/UI/ProductsList';
import counterImg from '../assets/images/counter-timer-img.png';
import Clock from '../components/UI/Clock';

import useGetData from '../custom-hooks/useGetData';

const Home = () => {

  const { data: products, loading } = useGetData('products');


  const year = new Date().getFullYear();

  const [trendingProducts, setTrendingProducts] = useState([]);
  const [bestSalesProducts, setBestSalesProducts] = useState([]);
  const [wirelessProducts, setWirelessProducts] = useState([]);
  const [mobileProducts, setMobileProducts] = useState([]);
  const [watchProducts, setWatchProducts] = useState([]);

  useEffect(() => {
    const filteredTrendingProducts = products.filter((item) => item.category === 'chair');
    const filteredBestSalesProducts = products.filter((item) => item.category === 'sofa');
    const filteredWirelessProducts = products.filter((item) => item.category === 'wireless');
    const filteredMobileProducts = products.filter((item) => item.category === 'mobile');
    const filteredWatchProducts = products.filter((item) => item.category === 'watch');

    setBestSalesProducts(filteredBestSalesProducts);
    setTrendingProducts(filteredTrendingProducts);
    setWirelessProducts(filteredWirelessProducts);
    setMobileProducts(filteredMobileProducts);
    setWatchProducts(filteredWatchProducts);

  }, [products])

  return (
    <Helmet title={"Home"}>
      <section className='hero__section'>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className='hero__content'>
                <p className="hero__subtitle">Trending product in {year}</p>
                <h2>Make your Interior More Minimalistic & Modern</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi illo vero dolores libero aperiam aut, nesciunt eligendi, ullam amet soluta eum rem non corrupti tenetur, provident deserunt et nisi! Sed.</p>
                <motion.button whileTap={{ scale: 1.2 }} className='buy__btn'><Link to="/shop">SHOP NOW</Link></motion.button>
              </div>
            </Col>
            <Col lg='6' md='6'>
              <div className="hero__img">
                <img src={heroImg} alt="heroImg" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Services />
      <section className='trending__products'>
        <Container>
          <Row>
            <Col lg='12' className='text-center'>
              <h2 className="section__tile">Trending Products</h2>
            </Col>
            {
              loading ? <h5 className='fw-bold'>Loading...</h5> : <ProductsList data={trendingProducts} />
            }

          </Row>
        </Container>
      </section>
      <section className='best__sales'>
        <Container>
          <Row>
            <Col lg='12' className='text-center'>
              <h2 className='section__title'>Best Sales</h2>
            </Col>
            {
              loading ? <h5 className='fw-bold'>Loading...</h5> : <ProductsList data={bestSalesProducts} />
            }
          </Row>
        </Container>
      </section>
      <section className='timer__count'>
        <Container>
          <Row>
            <Col lg='6' md='12' className='count__down-col'>
              <div className="clock__top-content">
                <h4 className='text-white mb-2'>Limited Offers</h4>
                <h3 className='text-white mb-3'>Quality Armchair</h3>
              </div>
              <Clock />
              <motion.button whileTap={{ scale: 1.2 }} className="buy__btn store__btn">
                <Link to="/shop">Visit Store</Link>
              </motion.button>
            </Col>
            <Col lg='6' md='12' className='text-right counter__img'>
              <img src={counterImg} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="new__arrivals">
        <Container>
          <Row>
            <Col lg='12' className='text-center mb-5'>
              <h2 className="section__title">New Arrivals</h2>
            </Col>
            {
              loading ? <h5 className='fw-bold'>Loading...</h5> : <ProductsList data={mobileProducts} />
            }
            {
              loading ? <h5 className='fw-bold'>Loading...</h5> : <ProductsList data={wirelessProducts} />
            }
          </Row>
        </Container>
      </section>
      <section className="popular__category">
        <Container>
          <Row>
            <Col lg='12' className='text-center mb-5'>
              <h2 className="section__title">Popular in Category</h2>
            </Col>
            {
              loading ? <h5 className='fw-bold'>Loading...</h5> : <ProductsList data={watchProducts} />
            }
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

export default Home