import React, { useEffect, useState } from 'react'


import '../../styles/clock.css'

const Clock = () => {

    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();

    let interval;

    const countDown = () => {
        var countDownDate = new Date("Jun 5, 2024 15:37:25").getTime();
        interval = setInterval(() => {
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval)
            } else {
                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }

        })
    }

    useEffect(() => {
        countDown();
    });

    return (
        <div className='clock__wrapper d-flex align-items-center' style={{ gap: "13px" }}>
            <div className='clock__data d-flex align-items-center' style={{ gap: "13px" }}>
                <div className="text-center">
                    <h1 className="text-white mb-2">{days}</h1>
                    <h5 className="text-white">Days</h5>
                </div>
                <div>
                    <span className="text-white">:</span>
                </div>
            </div>

            <div className='clock__data d-flex align-items-center' style={{ gap: "13px" }}>
                <div className="text-center">
                    <h1 className="text-white mb-2">{hours}</h1>
                    <h5 className="text-white">Hours</h5>
                </div>
                <span className="text-white">:</span>
            </div>

            <div className='clock__data d-flex align-items-center' style={{ gap: "13px" }}>
                <div className="text-center">
                    <h1 className="text-white fs-3 mb-2">{minutes}</h1>
                    <h5 className="text-white">Minutes</h5>
                </div>
                <span className="text-white fs-3">:</span>
            </div>

            <div className='clock__data d-flex align-items-center' style={{ gap: "13px" }}>
                <div className="text-center">
                    <h1 className="text-white fs-3 mb-2">{seconds}</h1>
                    <h5 className="text-white">Seconds</h5>
                </div>
            </div>
        </div>
    )
}

export default Clock