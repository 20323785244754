import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAUwP7mdrE53tiddwOd16-JFWp6oAKiUCU",
  authDomain: "fashionfusion-e7ed7.firebaseapp.com",
  projectId: "fashionfusion-e7ed7",
  storageBucket: "fashionfusion-e7ed7.appspot.com",
  messagingSenderId: "391761561522",
  appId: "1:391761561522:web:208353d49dc11ba2f646ab"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);


export default app;